// import logo from "./logo.svg";
// import "./App.css";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import { toast } from "material-react-toastify";
import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Courses from "../components/home/Courses";
import IconHeadphones from "../components/svgAssets/IconHeadphones";
import IconQuestion from "../components/svgAssets/IconQuestion";
import IconSpeaker from "../components/svgAssets/IconSpeaker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import TimePicker from "@mui/lab/TimePicker";
// import DateTimePicker from "@mui/lab/DateTimePicker";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import MobileDatePicker from "@mui/lab/MobileDatePicker";
// import StaticDatePicker from "@mui/lab/StaticDatePicker";
// import StaticTimePicker from "@mui/lab/StaticTimePicker";
import LineWaveRed from "../components/svgAssets/LineWaveRed";
import { contactUs } from "../redux/action/utils.action";
import { Link } from "react-router-dom";

function Edu() {
    const classes = useStyle();
    const cart = useSelector((state) => state.cart.items);
    const [bookingData, setBookingData] = useState({});
    const [dateValue, setDateValue] = useState(undefined);
    const [timeValue, setTimeValue] = useState(undefined);
    const [submitLoading, setSubmitLoading] = useState(false);
    const dispatch = useDispatch();
    const formRef = useRef();
    const formik = useFormik({
        initialValues: {
            name: "",
            surname: "",
            phone: "",
            email: "",
            message: "",
        },
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            setSubmitLoading(true);
            contactUs(values)
                .then((data) => {
                    toast.success(data.message, { position: "bottom-right" });
                    console.log("resetting");
                    formRef.current.reset();
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                })
                .finally(() => {
                    setSubmitLoading(false);
                });
        },
    });
    const [lastLocations, setLastLocations] = useState([
        {
            continentName: "NORTH AMERICA",
            locationArray: [
                "Las Vegas, Nevada",
                " Miami Florida",
                "Gilbert, Arizona",
                "Los Angeles, California",
                "Fort Wayne, California",
                "Chicago, Illinois",
                "New York, New York",
            ],
        },

        {
            continentName: "EUROPE",
            locationArray: [
                "Amsterdam, Netherlands",
                "Geneva, Switzerland",
                "Cologne, Germany",
                "Berlin, Germany",
                "London, England",
                "Milan, Italy",
                "Rome, Italy",
            ],
        },
        {
            continentName: "ASIA",
            locationArray: ["Beijing, China"],
        },
    ]);

    const seriesList = [{}, {}, {}, {}, {}, {}, {}];
    return (
        <div className={classes.home}>
            <Helmet>
                <title>Studio DMI | Mixing and Mastering Workshop Tour 2024</title>
                <meta charset="UTF-8" />
                <meta
                    name="description"
                    content="Studio DMI and Luca are passionate about the future of music and the industry, making contributions through live workshop tours, webinars and participating as a partner of MyMixLab.com, a website dedicated to sharing tricks and tips by various producers, engineers and artists."
                />
                <meta name="referrer" content="origin" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            {/* <div id="tour_24">
        <div>
          <Typography
            variant="h1"
            fontSize={"3rem"}
            align="center"
            color="primary"
            style={{ textAlign: "center", marginTop: "60px", padding: "16px 0" }}
          >
            Tour 2024
          </Typography>
        </div>
        <div
          className={classes.homeImageDiv}
          sx={{ height: { xs: "70vh", sm: "auto" } }}
        >
          <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%" maxHeight={"100vh"}>
            <img
              src="/images/edu/Luca Tour 24 Web Header.jpg"
              width="100%"
              height="100%"
              style={{ objectFit: "cover", filter: "grayscale(100%)" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.topContent}
            sx={{ padding: { xs: "100px 30px", sm: "148px 100px 0" } }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              position={"relative"}
              sx={{
                paddingBottom: { xs: 0, lg: "200px" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "320px",
                  width: { xs: "50%", sm: "70%" },
                }}
              >
                <img
                  src="/assets/closer-music.svg"
                  width="auto"
                  height="60px"
                />
                <h6
                  style={{
                    textAlign: "right",
                    color: "#fff",
                    margin: "-12px 0 0",
                    fontStyle: "italic",
                    textTransform: "lowercase",
                  }}
                >
                  Presents
                </h6>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.bottomContent}
            sx={{
              color: "#fff",
              width: "100%",
              display: { xs: "block", lg: "block" },
              padding: { xs: "0 16px", sm: "0 100px" },
              bottom: { xs: "16px", sm: "0px" },
            }}
          >
            <Box display="flex" justifyContent={"center"} fontSize="56px" fontFamily={'Montserrat'} lineHeight={"56px"} fontWeight="700">
              Luca Pretolesi
            </Box>
            <Box display="flex" justifyContent={"center"} fontFamily={'Montserrat'} fontSize="24px" fontWeight={"600"}>
              Mixing and Mastering Workshop Tour 2024
            </Box>
            <Box display="flex" justifyContent={"center"} fontSize="32px">
              <img
                src="/logo1.png"
                style={{
                  objectFit: "cover",
                  height: "auto",
                  width: "auto",
                  margin: "4px 0",
                }}
              />
            </Box>
            <Box display="flex" justifyContent={"space-between"} width={"100%"}>
              <Box width={"30%"} display="flex" justifyContent={"flex-start"}>
                <img
                  src="/images/logo-focal-2.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
              <Box width={"30%"} display="flex" justifyContent={"center"}>
                <img
                  src="/images/logo-acustica.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
              <Box width={"30%"} display="flex" justifyContent={"flex-end"}>
                <img
                  src="/images/edu/tour_24/logo-mymixlab.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>
        <div
          className={classes.homeImageDiv}
          sx={{ height: { xs: "100vh", sm: "100vh" } }}
        >
          <Box sx={{ height: { xs: "100vh", sm: "100vh" } }} width="100%">
            <img
              src="/images/edu/tour_24/Luca Tour 24 Web Descritption Background.jpg"
              width="100%"
              height="100%"
              style={{ objectFit: "cover", filter: "grayscale(100%)" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={"16px"}
            style={{ textShadow: "1px 1px 2px #212121" }}
            sx={{
              color: "#fff",
              position: "absolute",
              top: 0,
              left: 0,
              padding: { xs: "16px", sm: "48px" },
              backgroundColor: "#00000061"
            }}
          >
            <div style={{ fontSize: "18px", textAlign: "left" }}>
              Closer Music and Studio DMI present Luca Pretolesi's 2024 Mixing
              and Mastering Workshop World Tour! Attendees will experience an
              intimate masterclass where Luca walks through mixing and mastering
              a project from beginning to end, showcasing his workflow,
              techniques and offering personal instruction along with exciting
              bonuses in a rare opportunity to share space with one of the the
              most sought after and influential engineers in electronic music
              today.
            </div>
            <h4>COURSE OUTLINE</h4>
            <Box
              display="flex"
              justifyContent={"space-between"}
              flexWrap="wrap"
              gap="16px"
              rowGap={"32px"}
              textAlign={"left"}
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                gap="4px"
                alignItems={"flex-start"}
                sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
              >
                <h6 style={{ marginBottom: 0 }}>
                  1) INTRO & LISTENING SESSION
                </h6>
                Luca begins by outlining some of his biggest work with a look
                behind the hits
              </Box>
              <Box
                display={"flex"}
                flexDirection="column"
                gap="4px"
                alignItems={"flex-start"}
                sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
              >
                <h6 style={{ marginBottom: 0 }}>2) THE MIXING PROCESS</h6>
                An interactive lesson in which Luca walks through his process
                while mixing a song together with attendees from beginning to
                end
              </Box>
              <Box
                display={"flex"}
                flexDirection="column"
                gap="4px"
                alignItems={"flex-start"}
                sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
              >
                <h6 style={{ marginBottom: 0 }}>3) MASTERING</h6>
                The master of mastering goes through his process wrapping up the
                entire project, showcasing his workflow and working with
                attendees to hone their skills
              </Box>

              <Box
                display={"flex"}
                flexDirection="column"
                gap="4px"
                alignItems={"flex-start"}
                sx={{ width: { xs: "100%", sm: "calc(50% - 8px)" } }}
              >
                <h6 style={{ marginBottom: 0 }}>4) Q&A</h6>
                Luca spends dedicated time to work with guests directly and
                answer any questions
              </Box>
            </Box>
          </Box>
        </div>
        <div
          className={classes.homeImageDiv}
          style={{
            color: "#fff",
            textAlign: "left",
          }}
          sx={{ height: { xs: "100vh", sm: "100vh" } }}
        >
          <Box sx={{ height: { xs: "100vh", sm: "100vh" } }} width="100%">
            <img
              src="/images/edu/tour_24/Luca Tour 24 Sales Web Background.jpeg"
              width="100%"
              height="100%"
              style={{ objectFit: "cover", filter: "grayscale(100%) brightness(50%)" }}
            />
          </Box>
          <Box
            display={"flex"}
            flexDirection="column"
            gap={"64px"}
            width={"100%"}
            height="100%"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              padding: { xs: "16px", sm: "12px" },
            }}
          >
            <Box
              display={"flex"}
              justifyContent="space-between"
              width={"100%"}
              alignItems="center"
              sx={{
                textShadow: "1px 1px 2px #212121",
                flexDirection: { xs: "column", sm: "row" },
                textAlign: { xs: "center", sm: "left" },
                gap: { xs: 0, sm: "16px" },
              }}
            >
              <div>
                <img
                  src="/assets/closer-music.svg"
                  width="auto"
                  height="64px"
                />
              </div>
              <div>
                <img
                  src="/logo1.png"
                  width="100%"
                  height="auto"
                />
              </div>
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyItems={"center"}>
              <Box fontSize="48px" whiteSpace={"nowrap"} fontFamily={'Montserrat'} fontWeight={"700"}>
                Luca Pretolesi
              </Box>
              <Box
                fontSize="32px"
                style={{
                  backdropFilter: "blur(1px)",
                }}
              >
                Mixing and Mastering Workshop Tour 2024
              </Box>
            </Box>
            <Box
              className={classes.tour_ul}
              sx={{
                fontSize: { xs: "14px", sm: "18px" },
                padding: 0,
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>MIAMI, FL USA</Box>
                <Box className={classes.tour_venue}>ABBEY ROAD INST</Box>
                <Box className={classes.tour_date}>FRI MARCH, 22</Box>
                <Link to={"/tours/miami"}><button className={classes.tour_btn}>ADD TO CART</button></Link>
              </li>

              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>VENICE, ITALY</Box>
                <Box className={classes.tour_venue}>PRIVATE EVENT</Box>
                <Box className={classes.tour_date}>PRIVATE EVENT</Box>
                <div
                  className={classes.tour_btn}
                  style={{ visibility: "none", opacity: 0 }}
                ></div>
              </li>
              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>WARSAW, POLAND</Box>
                <Box className={classes.tour_venue}>CLOSER MUSIC HQ</Box>
                <Box className={classes.tour_date}>SAT APR 13</Box>
                <Link to={"/tours/warsaw"}><button className={classes.tour_btn}>ADD TO CART</button></Link>
              </li>
              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>PARIS, FRANCE</Box>
                <Box className={classes.tour_venue}>PRIVATE EVENT</Box>
                <Box className={classes.tour_date}>PRIVATE EVENT</Box>
                <div
                  className={classes.tour_btn}
                  style={{ visibility: "none", opacity: 0 }}
                ></div>
              </li>
              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>RIMINI, ITALY</Box>
                <Box className={classes.tour_venue}>MIR</Box>
                <Box className={classes.tour_date}>SAT APR 6</Box>
                <div
                  className={classes.tour_btn}
                  style={{ visibility: "none", opacity: 0 }}
                ></div>
              </li>
              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>AMSTERDAM, NL</Box>
                <Box className={classes.tour_venue}>ABBEY ROAD INST</Box>
                <Box className={classes.tour_date}>FRI APR 12</Box>
                <Link to={"/tours/amsterdam"}><button className={classes.tour_btn}>ADD TO CART</button></Link>
              </li>

              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>MILAN, ITALY</Box>
                <Box className={classes.tour_venue}>MAT ACADEMY</Box>
                <Box className={classes.tour_date}>SAT APR 13</Box>
                <div
                  className={classes.tour_btn}
                  style={{ visibility: "none", opacity: 0 }}
                ></div>
              </li>

              <li className={classes.tour_list}>
                <Box className={classes.tour_city}>LOS ANGELES, CA USA</Box>
                <Box className={classes.tour_venue}>ICON COLLECTIVE</Box>
                <Box className={classes.tour_date}>TBD</Box>
                <div
                  className={classes.tour_btn}
                  style={{ visibility: "none", opacity: 0 }}
                ></div>
              </li>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            className={classes.bottomContent}
            sx={{
              color: "#fff",
              width: "100%",
              display: { xs: "block", lg: "block" },
              padding: { xs: "0 16px", sm: "0 100px" },
              bottom: { xs: "16px", sm: "4px" },
            }}
          >
            <Box display="flex" justifyContent={"space-between"} width={"100%"}>
              <Box width={"30%"} display="flex" justifyContent={"flex-start"}>
                <img
                  src="/images/logo-focal-2.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
              <Box width={"30%"} display="flex" justifyContent={"center"}>
                <img
                  src="/images/logo-acustica.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
              <Box width={"30%"} display="flex" justifyContent={"flex-end"}>
                <img
                  src="/images/edu/tour_24/logo-mymixlab.png"
                  style={{
                    objectFit: "contain",
                    height: "auto",
                    width: "150px",
                    margin: "24px 0",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </div>
      </div> */}


            {/* <div style={{ marginTop: "60px", background: "black" }}>
        <Grid container spacing={1} style={{ display: "flex", justifyContent: 'center' }}>
          <Grid item xs={12} md={8}>
            <img src="/images/edu/Tours1.jpg" style={{ marginBottom: "-8px" }} width="100%" height="auto" />
            <img src="/images/edu/Tours2.jpg" style={{ marginBottom: "-8px" }} width="100%" height="auto" />
            <img src="/images/edu/Tours3.jpg" width="100%" height="auto" />
            <Box bgcolor={'#E2211C'} padding={4} justifyContent={"center"}>
              <a target="_blank" href="https://www.eventbrite.com/o/studio-dmi-79207275753" style={{ color: "white", textAlign: "center" }} ><h3>Click Here to get Tickets</h3></a>
            </Box>
          </Grid>
        </Grid>
      </div> */}



            {/* <div>
        <Typography
          variant="h1"
          align="center"
          color="primary"
          fontSize={"3rem"}
          style={{ textAlign: "center", padding: "16px 0" }}
        >
          Webinars
        </Typography>
      </div> */}





            <div
                className={classes.homeImageDiv}
                sx={{ height: { xs: "70vh", sm: "auto" } }}
            >
                <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%">
                    {/*src="/images/edu/EDU_MAIN.jpg"*/}
                    <img
                        src="/images/edu/tour_24/TourDesktopBG.jpg"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.topContent}
                    sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="right"
                        sx={{
                            paddingTop: { xs: 0, lg: "20rem" },
                            paddingBottom: { xs: 0, lg: "00px" },
                            paddingRight: { xs: 0, lg: "10rem" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "50%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/CloserLogoTour.png" width="100%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "00px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/TourTextOverlay.png" width="120%!important" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "400px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "50%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/BottomLogos.png" width="120%!important" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ display: { xs: "none", lg: "flex" } }}
                    className={classes.sideContent}
                >
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    flexDirection="column"*/}
                    {/*    justifyContent="space-between"*/}
                    {/*    height="400px"*/}
                    {/*    paddingBottom="200px"*/}
                    {/*    className={classes.rotatedButton}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.facebook.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Facebook*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://twitter.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Twitter*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.instagram.com/studiodmi/"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Instagram*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*</Box>*/}
                </Box>
                {/*<Box*/}
                {/*    display="flex"*/}
                {/*    flexDirection="column"*/}
                {/*    className={classes.bottomContent}*/}
                {/*    sx={{ display: { xs: "none", lg: "block" } }}*/}
                {/*>*/}
                {/*    <Box display="flex">*/}
                {/*        <Grid container spacing={1} alignItems="center">*/}
                {/*            <Grid item xs={12} md={3}>*/}
                {/*                <Box display="flex">*/}
                {/*                    <Typography variant="h4" color="secondary">*/}
                {/*                        OUR*/}
                {/*                    </Typography>*/}
                {/*                    &nbsp;&nbsp;&nbsp;*/}
                {/*                    <Typography color="textSecondary" variant="h4">*/}
                {/*                        MISSION*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} md={9}>*/}
                {/*                <Box display="flex" alignItems="center">*/}
                {/*                    <LineWaveRed*/}
                {/*                        style={{*/}
                {/*                            width: "100%",*/}
                {/*                            color: "rgba(0,0,0,0.001)",*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Box>*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*    <Grid container spacing={6} direction="row" justify="space-between">*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Studio DMI and Luca are passionate about the future of music and*/}
                {/*                the industry, making contributions through live workshop tours,*/}
                {/*                webinars and participating as a partner of MyMixLab.com, a*/}
                {/*                website dedicated to sharing tricks and tips by various*/}
                {/*                producers, engineers and artists.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Whether you’re attending a "Master Series" or "Deep Dive*/}
                {/*                Session", you’re guaranteed to learn mixing techniques that will*/}
                {/*                help you complete projects faster, as well as real-life*/}
                {/*                applications for a career in music.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
            </div>

            <div
                className={classes.homeImageDiv}
                sx={{ height: { xs: "70vh", sm: "auto" } }}
            >
                <Box sx={{ height: { xs: "70vh", sm: "auto" } }} width="100%">
                    {/*src="/images/edu/EDU_MAIN.jpg"*/}
                    <img
                        src="/images/edu/tour_24/CourseDescription.jpg"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                    />
                </Box>
                <Box
                    backgroundColor="rgba(0, 0, 0, .6);"
                    
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.topContent}
                    sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
                >
                    <Box 
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingTop: { xs: 0, lg: "10rem" },
                            paddingBottom: { xs: 0, lg: "00px" },
                            paddingRight: { xs: 0, lg: "0rem" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "100%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/TopDescription.png" width="90%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "00px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "100%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/CourseOutline.png" width="90%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "100px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "80%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/DescriptionLogos.png" width="100%!important" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ display: { xs: "none", lg: "flex" } }}
                    className={classes.sideContent}
                >
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    flexDirection="column"*/}
                    {/*    justifyContent="space-between"*/}
                    {/*    height="400px"*/}
                    {/*    paddingBottom="200px"*/}
                    {/*    className={classes.rotatedButton}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.facebook.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Facebook*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://twitter.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Twitter*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.instagram.com/studiodmi/"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Instagram*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*</Box>*/}
                </Box>
                {/*<Box*/}
                {/*    display="flex"*/}
                {/*    flexDirection="column"*/}
                {/*    className={classes.bottomContent}*/}
                {/*    sx={{ display: { xs: "none", lg: "block" } }}*/}
                {/*>*/}
                {/*    <Box display="flex">*/}
                {/*        <Grid container spacing={1} alignItems="center">*/}
                {/*            <Grid item xs={12} md={3}>*/}
                {/*                <Box display="flex">*/}
                {/*                    <Typography variant="h4" color="secondary">*/}
                {/*                        OUR*/}
                {/*                    </Typography>*/}
                {/*                    &nbsp;&nbsp;&nbsp;*/}
                {/*                    <Typography color="textSecondary" variant="h4">*/}
                {/*                        MISSION*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} md={9}>*/}
                {/*                <Box display="flex" alignItems="center">*/}
                {/*                    <LineWaveRed*/}
                {/*                        style={{*/}
                {/*                            width: "100%",*/}
                {/*                            color: "rgba(0,0,0,0.001)",*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Box>*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*    <Grid container spacing={6} direction="row" justify="space-between">*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Studio DMI and Luca are passionate about the future of music and*/}
                {/*                the industry, making contributions through live workshop tours,*/}
                {/*                webinars and participating as a partner of MyMixLab.com, a*/}
                {/*                website dedicated to sharing tricks and tips by various*/}
                {/*                producers, engineers and artists.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Whether you’re attending a "Master Series" or "Deep Dive*/}
                {/*                Session", you’re guaranteed to learn mixing techniques that will*/}
                {/*                help you complete projects faster, as well as real-life*/}
                {/*                applications for a career in music.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
            </div>

            <div
                className={classes.homeImageDiv}
                sx={{ height: { xs: "70vh", sm: "auto" } }}
            >
                <Box sx={{ height: { xs: "70vh", sm: "auto" } }}
                    

                    width="100%"
                    
                    >
                    {/*src="/images/edu/EDU_MAIN.jpg"*/}
                    <img
                        src="/images/edu/tour_24/TicketPageBackground.jpeg"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "cover" }}
                    />
                </Box>
                <Box
                    display="flex"
                    backgroundColor="rgba(0, 0, 0, .4);"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.topContent}
                    sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
                >
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingTop: { xs: 0, lg: "1rem" },
                            paddingBottom: { xs: 0, lg: "0rem" },
                            paddingRight: { xs: 0, lg: "0rem" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/CloserLogoTour.png" width="75%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0rem" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "75%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/TicketsTextOverlay.png" width="100%!important" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        backgroundColor="rgba(0, 0, 0, .7);"

                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <a href="https://www.eventbrite.com/e/luca-pretolesi-mixing-and-mastering-workshop-tour-2024-miami-tickets-842118367707">
                                <img src="/images/edu/tour_24/tickets/Miami.png" width="100%" height="auto" />
                            </a>
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        backgroundColor="rgba(0, 0, 0, .7);"
                        width="100%"

                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/tickets/Venice.png" width="100%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        backgroundColor="rgba(0, 0, 0, .7);"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <a href="https://www.eventbrite.com/e/luca-pretolesi-mixing-and-mastering-workshop-tour-2024-warsaw-tickets-843131207137">
                                <img src="/images/edu/tour_24/tickets/Warsaw.png" width="100%" height="auto" />
                            </a>
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        backgroundColor="rgba(0, 0, 0, .7);"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/tickets/Paris.png" width="100%" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>


                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        backgroundColor="rgba(0, 0, 0, .7);"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <a href="https://www.algameko.com/it/new/luca-pretolesi-dolby-atmos-nella-produzione-musicale">
                            <img src="/images/edu/tour_24/tickets/Rimini.png" width="100%" height="auto" />
                            </a>
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>


                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        backgroundColor="rgba(0, 0, 0, .7);"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <a href="https://www.eventbrite.com/e/tickets-luca-pretolesi-mixing-and-mastering-workshop-tour-2024-amsterdam-842585254177">
                            <img src="/images/edu/tour_24/tickets/Amsterdam.png" width="100%" height="auto" />
                            </a>
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        backgroundColor="rgba(0, 0, 0, .7);"
                        sx={{
                            paddingBottom: { xs: 0, lg: "0px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "60%" },
                            }}
                        >
                            <a href="https://corsi.mat-academy.com/luca-pretolesi-workshop-13-aprile-2024/">
                                <img src="/images/edu/tour_24/tickets/Milan.png" width="100%" height="auto" />
                            </a>
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        sx={{
                            paddingBottom: { xs: 0, lg: "000px" },
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", sm: "50%" },
                            }}
                        >
                            <img src="/images/edu/tour_24/TicketPageLogos.png" width="120%!important" height="auto" />
                        </Box>
                        {/*                        <img src="/homeTextOverlay.png" width="100%" height="auto" />*/}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    sx={{ display: { xs: "none", lg: "flex" } }}
                    className={classes.sideContent}
                >
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    flexDirection="column"*/}
                    {/*    justifyContent="space-between"*/}
                    {/*    height="400px"*/}
                    {/*    paddingBottom="200px"*/}
                    {/*    className={classes.rotatedButton}*/}
                    {/*>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.facebook.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Facebook*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://twitter.com/StudioDMI"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Twitter*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*    <Typography*/}
                    {/*        variant="subtitle1"*/}
                    {/*        color="textSecondary"*/}
                    {/*        className={classes.verticalText}*/}
                    {/*    >*/}
                    {/*        <a*/}
                    {/*            href="https://www.instagram.com/studiodmi/"*/}
                    {/*            target="_blank"*/}
                    {/*            style={{ color: "#fff" }}*/}
                    {/*        >*/}
                    {/*            Instagram*/}
                    {/*        </a>*/}
                    {/*    </Typography>*/}
                    {/*</Box>*/}
                </Box>
                {/*<Box*/}
                {/*    display="flex"*/}
                {/*    flexDirection="column"*/}
                {/*    className={classes.bottomContent}*/}
                {/*    sx={{ display: { xs: "none", lg: "block" } }}*/}
                {/*>*/}
                {/*    <Box display="flex">*/}
                {/*        <Grid container spacing={1} alignItems="center">*/}
                {/*            <Grid item xs={12} md={3}>*/}
                {/*                <Box display="flex">*/}
                {/*                    <Typography variant="h4" color="secondary">*/}
                {/*                        OUR*/}
                {/*                    </Typography>*/}
                {/*                    &nbsp;&nbsp;&nbsp;*/}
                {/*                    <Typography color="textSecondary" variant="h4">*/}
                {/*                        MISSION*/}
                {/*                    </Typography>*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*            <Grid item xs={12} md={9}>*/}
                {/*                <Box display="flex" alignItems="center">*/}
                {/*                    <LineWaveRed*/}
                {/*                        style={{*/}
                {/*                            width: "100%",*/}
                {/*                            color: "rgba(0,0,0,0.001)",*/}
                {/*                        }}*/}
                {/*                    />*/}
                {/*                </Box>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Box>*/}
                {/*    <br />*/}
                {/*    <br />*/}
                {/*    <Grid container spacing={6} direction="row" justify="space-between">*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Studio DMI and Luca are passionate about the future of music and*/}
                {/*                the industry, making contributions through live workshop tours,*/}
                {/*                webinars and participating as a partner of MyMixLab.com, a*/}
                {/*                website dedicated to sharing tricks and tips by various*/}
                {/*                producers, engineers and artists.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*        <Grid item md={6}>*/}
                {/*            <Typography align="left" variant="h6" color="textSecondary">*/}
                {/*                Whether you’re attending a "Master Series" or "Deep Dive*/}
                {/*                Session", you’re guaranteed to learn mixing techniques that will*/}
                {/*                help you complete projects faster, as well as real-life*/}
                {/*                applications for a career in music.*/}
                {/*            </Typography>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
            </div>









            {/*<Box*/}
            {/*    display="flex"*/}
            {/*    flexDirection="column"*/}
            {/*    sx={{*/}
            {/*        display: { xs: "block", lg: "none" },*/}
            {/*        padding: { xs: "20px 30px", sm: "50px 100px" },*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Box*/}
            {/*        display="flex"*/}
            {/*        sx={{ margin: { xs: "0 0 10px", md: "0 0 50px 0" } }}*/}
            {/*    >*/}
            {/*        <Grid container spacing={1} alignItems="center">*/}
            {/*            <Grid item xs={12} md={3}>*/}
            {/*                <Box display="flex">*/}
            {/*                    <Typography variant="h4" color="secondary">*/}
            {/*                        OUR*/}
            {/*                    </Typography>*/}
            {/*                    &nbsp;&nbsp;&nbsp;*/}
            {/*                    <Typography variant="h4">MISSION</Typography>*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*            <Grid item xs={12} md={9}>*/}
            {/*                <Box display="flex" alignItems="center">*/}
            {/*                    <LineWaveRed*/}
            {/*                        style={{*/}
            {/*                            width: "100%",*/}
            {/*                            color: "rgba(0,0,0,0.001)",*/}
            {/*                        }}*/}
            {/*                    />*/}
            {/*                </Box>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </Box>*/}
            {/*    <Grid container spacing={6} direction="row" justify="space-between">*/}
            {/*        <Grid item md={6} xs={12}>*/}
            {/*            <Typography align="left" variant="h6">*/}
            {/*                At Studio DMI, we have a passion for sharing the experience we’ve*/}
            {/*                gained over the years, mixing and mastering for some of the*/}
            {/*                biggest names in the industry. Everyone on our team strives to be*/}
            {/*                better than we were yesterday, and we want to help you do the*/}
            {/*                same. It’s important to us that you leave a webinar*/}
            {/*                workshop/masterclass with a better understanding of mixing and*/}
            {/*                mastering (occasionally production) than when you walked in.*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        <Grid item md={6} xs={12}>*/}
            {/*            <Typography align="left" variant="h6">*/}
            {/*                Whether you’re attending a "Master Series" or "Deep Dive Session",*/}
            {/*                you’re guaranteed to learn mixing techniques that will help you*/}
            {/*                complete projects faster, as well as real-life applications for a*/}
            {/*                career in music.*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Box>*/}




            {/*            <Courses />*/}




            {/* <Box display="flex" justifyContent="center" height="auto">
        <Carousel
          showThumbs={false}
          centerMode={false}
          autoPlay={false}
          showStatus={false}
          infiniteLoop={true}
          showIndicators={true}
          selectedItem={1}
          swipeable={true}
          emulateTouch={true}
        >
          <div>
            <img src="/homeCarousel1.png" />
          </div>
          <div>
            <img src="/homeCarousel1.png" />
          </div>
          <div>
            <img src="/homeCarousel1.png" />
          </div>
        </Carousel>
      </Box> */}



            {/* <Box
        display="flex"
        flexDirection="column"
        sx={{
          padding: { xs: "20px 30px", sm: "50px 100px" },
        }}
      >
        <Box
          display="flex"
          sx={{ margin: { xs: "0 0 10px", md: "0 0 50px 0" } }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md={3}>
              <Box display="flex">
                <Typography variant="h4" color="secondary">
                  master
                </Typography>
                &nbsp;&nbsp;&nbsp;
                <Typography variant="h4">series</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box display="flex" alignItems="center">
                <LineWaveRed
                  style={{
                    width: "100%",
                    color: "rgba(0,0,0,0.001)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={6} direction="row" justify="space-between">
          <Grid item md={6} xs={12}>
            <Typography align="left" variant="h6">
              Luca Pretolesi brings his Mixing & Mastering Workshop online for a
              live, all-access and hands on experience featuring a 6-episode
              series detailing Luca’s unique style and technique.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography align="left" variant="h6">
              Each course will be one hour and thirty minutes (1 Hour 30
              minutes) and detail one area of specific instruction and will be
              followed by a 30-minute Q&A for Luca to directly answer any
              questions that arise during the session. Take one course or take
              them all to experience Luca's dynamic approach to mixing and
              mastering.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        className={classes.onlineMentoring}
        sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
      >
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography variant="h4" color="secondary">
            online
          </Typography>
          &nbsp;&nbsp;&nbsp;
          <Typography variant="h4" color="textSecondary">
            mentoring
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="button" color="secondary">
            now available
          </Typography>
        </Box>
        <br />
        <Typography align="left" variant="button" color="textSecondary">
          One-on-one 1 hour Live Online Q&A Session with Luca Pretolesi
        </Typography>
        <br />
        <br />
        <Grid container spacing={6} direction="row" justify="space-between">
          <Grid item sm={4} xs={12}>
            <Box
              display="flex"
              marginBottom="5px"
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
            >
              <IconHeadphones
                style={{ width: "72px", height: "72px" }}
                color="background"
              />
            </Box>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Listen to Luca’s work directly from his DAW through your speakers
              or headphones
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box
              display="flex"
              marginBottom="5px"
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
            >
              <IconQuestion
                style={{ width: "72px", height: "72px" }}
                color="background"
              />
            </Box>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Take advantage of the extremely unique opportunity to ask Luca any
              questions without limitation or outside distractions
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Box
              display="flex"
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              marginBottom="5px"
            >
              <IconSpeaker
                style={{ width: "72px", height: "72px" }}
                color="background"
              />
            </Box>
            <Typography
              color="textSecondary"
              variant="subtitle1"
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              A special Zoom meeting link and exclusive software for 44Khz 24bit
              audio quality will be provided
            </Typography>
          </Grid>
        </Grid>
      </Box> */}






            {/*  <form*/}
            {/*      onSubmit={formik.handleSubmit}*/}
            {/*      ref={formRef}*/}
            {/*      style={{ width: "100%" }}*/}
            {/*  >*/}
            {/*      <Box display="flex" justifyContent="center">*/}
            {/*          <Box*/}
            {/*              display="flex"*/}
            {/*              flexDirection="column"*/}
            {/*              width="60%"*/}
            {/*              sx={{ display: { xs: "none", sm: "flex" } }}*/}
            {/*          >*/}
            {/*              <img*/}
            {/*                  src="/images/edu/EDU_SECONDARY.jpg"*/}
            {/*                  style={{ width: "100%", height: "100%", objectFit: "cover" }}*/}
            {/*              />*/}
            {/*          </Box>*/}
            {/*          <Box*/}
            {/*              display="flex"*/}
            {/*              flexDirection="column"*/}
            {/*              padding="45px 55px 45px 55px"*/}
            {/*              className={classes.userForm}*/}
            {/*              sx={{ width: { xs: "100%", md: "520px", lg: "40%" } }}*/}
            {/*          >*/}
            {/*              <Typography align="left" variant="h4" color="textSecondary">*/}
            {/*                  Contact us*/}
            {/*              </Typography>*/}
            {/*              <br />*/}
            {/*              <Grid container spacing={2}>*/}
            {/*                  <Grid item sm={6} xs={12}>*/}
            {/*                      <TextField*/}
            {/*                          fullWidth*/}
            {/*                          id="name"*/}
            {/*                          name="name"*/}
            {/*                          onChange={formik.handleChange}*/}
            {/*                          placeholder="Name"*/}
            {/*                          variant="outlined"*/}
            {/*                          style={{ background: "white" }}*/}
            {/*                      />*/}
            {/*                  </Grid>*/}
            {/*                  <Grid item sm={6} xs={12}>*/}
            {/*                      <TextField*/}
            {/*                          fullWidth*/}
            {/*                          id="surname"*/}
            {/*                          name="surname"*/}
            {/*                          // onChange={(e) => { setBookingData({ ...bookingData, surname: e.target.value }) }}*/}
            {/*                          onChange={formik.handleChange}*/}
            {/*                          placeholder="Surname"*/}
            {/*                          variant="outlined"*/}
            {/*                          style={{ background: "white" }}*/}
            {/*                      />*/}
            {/*                  </Grid>*/}
            {/*                  <Grid item sm={6} xs={12}>*/}
            {/*                      <TextField*/}
            {/*                          fullWidth*/}
            {/*                          id="email"*/}
            {/*                          name="email"*/}
            {/*                          // onChange={(e) => { setBookingData({ ...bookingData, email: e.target.value }) }}*/}
            {/*                          onChange={formik.handleChange}*/}
            {/*                          placeholder="Email"*/}
            {/*                          variant="outlined"*/}
            {/*                          style={{ background: "white" }}*/}
            {/*                      />*/}
            {/*                  </Grid>*/}
            {/*                  <Grid item sm={6} xs={12}>*/}
            {/*                      <TextField*/}
            {/*                          fullWidth*/}
            {/*                          id="phone"*/}
            {/*                          name="phone"*/}
            {/*                          // onChange={(e) => { setBookingData({ ...bookingData, phone: e.target.value }) }}*/}
            {/*                          onChange={formik.handleChange}*/}
            {/*                          placeholder="Phone number"*/}
            {/*                          variant="outlined"*/}
            {/*                          style={{ background: "white" }}*/}
            {/*                      />*/}
            {/*                  </Grid>*/}
            {/*                  <Grid item sm={12}>*/}
            {/*                      <TextField*/}
            {/*                          fullWidth*/}
            {/*                          id="message"*/}
            {/*                          name="message"*/}
            {/*                          style={{ background: "white" }}*/}
            {/*                          multiline*/}
            {/*                          rows={2}*/}
            {/*                          // onChange={(e) => { setBookingData({ ...bookingData, message: e.target.value }) }}*/}
            {/*                          onChange={formik.handleChange}*/}
            {/*                          placeholder="Message"*/}
            {/*                          variant="outlined"*/}
            {/*                      />*/}
            {/*                  </Grid>*/}
            {/*              </Grid>*/}
            {/*              <br />*/}
            {/*              */}{/* <Grid container spacing={2} justifyContent="space-between">*/}{/*
          */}{/*  <Grid item md={7} xs={12}>*/}{/*
          */}{/*    <Typography*/}{/*
          */}{/*      align="left"*/}{/*
          */}{/*      variant="subtitle1"*/}{/*
          */}{/*      color="textSecondary"*/}{/*
          */}{/*    >*/}{/*
          */}{/*      Select Date*/}{/*
          */}{/*    </Typography>*/}{/*
          */}{/*    <LocalizationProvider dateAdapter={AdapterDateFns}>*/}{/*
          */}{/*      <StaticDatePicker*/}{/*
          */}{/*        displayStaticWrapperAs="desktop"*/}{/*
          */}{/*        openTo="day"*/}{/*
          */}{/*        value={dateValue ? new Date(dateValue) : null}*/}{/*
          */}{/*        onChange={(newValue) => {*/}{/*
          */}{/*          console.log("newValue", newValue)*/}{/*
          */}{/*          setDateValue(newValue);*/}{/*
          */}{/*        }}*/}{/*
          */}{/*        renderInput={(params) => <TextField {...params} />}*/}{/*
          */}{/*      />*/}{/*
          */}{/*    </LocalizationProvider>*/}{/*
          */}{/*  </Grid>*/}{/*
          */}{/*  <Grid item xl={4} xs={12}>*/}{/*
          */}{/*    {dateValue && <Typography*/}{/*
          */}{/*      align="left"*/}{/*
          */}{/*      variant="subtitle1"*/}{/*
          */}{/*      color="textSecondary"*/}{/*
          */}{/*    >*/}{/*
          */}{/*      Select Session*/}{/*
          */}{/*    </Typography>}*/}{/*
          */}{/*    <Box width="100%" display="flex">*/}{/*
          */}{/*      <Session date={dateValue} timeValue={timeValue} setTimeValue={setTimeValue} />*/}{/*

          */}{/*      <LocalizationProvider dateAdapter={AdapterDateFns}>*/}{/*
          */}{/*        <TimePicker*/}{/*
          */}{/*          value={timeValue}*/}{/*
          */}{/*          onChange={(newValue) => {*/}{/*
          */}{/*            setTimeValue(newValue);*/}{/*
          */}{/*          }}*/}{/*
          */}{/*          renderInput={(params) => <TextField {...params} />}*/}{/*
          */}{/*        />*/}{/*
          */}{/*      </LocalizationProvider>*/}{/*
          */}{/*    </Box>*/}{/*
          */}{/*  </Grid>*/}{/*
          */}{/*</Grid>{" "} */}
            {/*              */}{/* <br />*/}{/*
          */}{/*<Box display="flex" alignItems="center" marginBottom="5px">*/}{/*
          */}{/*  <Add className={classes.customAddIcon} />*/}{/*
          */}{/*  &nbsp;&nbsp;&nbsp;{" "}*/}{/*
          */}{/*  <Typography align="left" variant="subtitle1" color="textSecondary">*/}{/*
          */}{/*    1 hour session - $250 2 hour session - $500 Every 10 hours,*/}{/*
          */}{/*    students get 1 hour free.*/}{/*
          */}{/*  </Typography>*/}{/*
          */}{/*</Box>*/}{/*
          */}{/*<Box display="flex" alignItems="center" marginBottom="5px">*/}{/*
          */}{/*  <Add className={classes.customAddIcon} />*/}{/*
          */}{/*  &nbsp;&nbsp;&nbsp;*/}{/*
          */}{/*  <Typography align="left" variant="subtitle1" color="textSecondary">*/}{/*
          */}{/*    2 hour session - $500 Every 10 hours, students get 1 hour free.*/}{/*
          */}{/*  </Typography>*/}{/*
          */}{/*</Box>*/}{/*
          */}{/*<Box display="flex" alignItems="center" marginBottom="5px">*/}{/*
          */}{/*  {" "}*/}{/*
          */}{/*  <Add className={classes.customAddIcon} />*/}{/*
          */}{/*  &nbsp;&nbsp;&nbsp;*/}{/*
          */}{/*  <Typography align="left" variant="subtitle1" color="textSecondary">*/}{/*
          */}{/*    Every 10 hours, students get 1 hour free.*/}{/*
          */}{/*  </Typography>*/}{/*
          */}{/*</Box>*/}{/*
          */}{/*<br /> */}
            {/*              <Grid container spacing={5}>*/}
            {/*                  */}{/* <Grid item sm={6} xs={12}>*/}{/*
          */}{/*      <Typography variant="subtitle1" color="textSecondary">*/}{/*
          */}{/*        <Button fullWidth variant="outlined" color="inherit"*/}{/*
          */}{/*          onClick={() => {*/}{/*
          */}{/*            setBookingData({});*/}{/*
          */}{/*            setTimeValue(undefined)*/}{/*
          */}{/*            setDateValue(new Date())*/}{/*
          */}{/*          }}*/}{/*
          */}{/*        >*/}{/*
          */}{/*          Reset all*/}{/*
          */}{/*        </Button>*/}{/*
          */}{/*      </Typography>*/}{/*
          */}{/*    </Grid> */}
            {/*                  <Grid item sm={6} xs={12}>*/}
            {/*                      <Button*/}
            {/*                          variant="contained"*/}
            {/*                          fullWidth*/}
            {/*                          // onClick={() => {*/}
            {/*                          //   console.log('cart', cart)*/}
            {/*                          //   dispatch(addCartItem({*/}
            {/*                          //     _id: "session_" + timeValue,*/}
            {/*                          //     type: "session",*/}
            {/*                          //     date: dateValue,*/}
            {/*                          //     time: timeValue,*/}
            {/*                          //     price: "50",*/}
            {/*                          //     name: "Session: " + dateValue + " " + timeValue*/}
            {/*                          //   }))*/}
            {/*                          //}}*/}
            {/*                          disabled={submitLoading}*/}
            {/*                          type="submit"*/}
            {/*                      >*/}
            {/*                          {submitLoading ? <CircularProgress size={32} /> : "Submit"}*/}
            {/*                      </Button>*/}
            {/*                  </Grid>*/}
            {/*              </Grid>*/}
            {/*          </Box>*/}
            {/*      </Box>*/}
            {/*  </form>*/}






            <Box
                display="flex"
                flexDirection="column"
                className={classes.onlineMentoring}
                sx={{ padding: { xs: "20px 30px", sm: "50px 100px" } }}
            >
                {/*<Box display="flex" alignItems="center">*/}
                {/*    <Typography variant="h4" color="secondary">*/}
                {/*        Past Live*/}
                {/*    </Typography>*/}
                {/*    &nbsp;&nbsp;&nbsp;*/}
                {/*    <Typography variant="h4" color="textSecondary">*/}
                {/*        Workshops*/}
                {/*    </Typography>*/}
                {/*</Box>*/}
                {/*                <br />*/}
                {/* <img src="/pastWorkshopMap.png" width="100%" height="auto" />*/}

                {/*<br />*/}
                {/*<br />*/}
                {/*<Grid container spacing={6} justifyContent="space-between">*/}
                {/*    {lastLocations.map((item) => (*/}
                {/*        <Grid item md={3} sm={6} xs={12} key={item.continentName}>*/}
                {/*            <Box display="flex" flexDirection="column">*/}
                {/*                <Typography align="left" color="textSecondary" variant="h6">*/}
                {/*                    {item.continentName}*/}
                {/*                </Typography>*/}
                {/*                <Divider className={classes.customDivider} />*/}
                {/*                <br />*/}
                {/*                {item.locationArray.map((location) => (*/}
                {/*                    <Typography*/}
                {/*                        key={location}*/}
                {/*                        align="left"*/}
                {/*                        color="textSecondary"*/}
                {/*                        variant="subtitle1"*/}
                {/*                    >*/}
                {/*                        {location}*/}
                {/*                    </Typography>*/}
                {/*                ))}*/}
                {/*            </Box>*/}
                {/*        </Grid>*/}
                {/*    ))}*/}
                {/*</Grid>*/}
                {/*<br />*/}
                {/*<br />*/}
                {/* <Divider color="#fff" />
        <br />
        <br /> */}
                {/* <Box display="flex" alignItems="center">
          <Typography variant="h4" color="textSecondary">
            TESTIMONIALS
          </Typography>
        </Box>
        <br />
        <br /> */}
                {/* <Grid container spacing={5}>
          <Grid item lg={6} xs={12}>
            <img src="/testimonial1.png" className={classes.testimonialCard} />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="button" color="textSecondary">
                Massimo Pezzetta /
              </Typography>
              <Typography variant="button" color="secondary">
                Italy
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img src="/testimonial2.png" className={classes.testimonialCard} />
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography variant="button" color="textSecondary">
                Attendee /
              </Typography>
              <Typography variant="button" color="secondary">
                Holland
              </Typography>
            </Box>
          </Grid>
        </Grid> */}

                {/* <br />
        <br /> */}
                <Divider color="#fff" />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="30px 0 0"
                >
                    <Typography variant="button" color="textSecondary">
                        ALL RIGHTS RESERVED ©StudioDMI {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
}

const useStyle = makeStyles((theme) => ({
    home: {
        textAlign: "center",
        justifyContent: "center",
    },
    homeImageDiv: {
        position: "relative",
        width: "100%",
        zIndex: 112,
        marginBottom: -7,
    },
    seriesList: {
        position: "relative",
        zIndex: 113,
        marginBottom: -7,
    },
    listSelection: {
        height: "100%",
    },
    topContent: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 112,
    },
    bottomContent: {
        position: "absolute",
        bottom: 60,
        left: 0,
        padding: "0 100px",
        zIndex: 113,
    },

    onlineMentoring: {
        backgroundColor: theme.palette.background.main,
    },
    userForm: {
        backgroundColor: theme.palette.secondary.main,
    },
    //sideContent: {
    //    position: "absolute",
    //    height: "99.5%",
    //    top: 0,
    //    right: 0,
    //    background: `linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,1)  93.76%)`,
    //    paddingLeft: 600,
    //    paddingRight: 100,
    //    zIndex: 112,
    //    // backdropFilter: "blur(6px)",
    //},
    rotatedButton: {
        backdropFilter: "inherit",
    },
    headingText: {
        fontWeight: 600,
    },
    labelText: {
        backgroundColor: theme.palette.secondary.main,
        padding: "2px 14px",
    },
    verticalText: {
        transform: "rotate(-90deg)",
    },
    borderText: {
        "-webkit-text-stroke": "1px #1A1A30",
    },
    promoCodeBox: {
        backgroundColor: "#AAC9DD",
        borderRadius: "44px",
    },
    customAddIcon: {
        border: "1px solid #fff",
        borderRadius: "25px",
        color: "#fff",
    },
    customDivider: {
        height: "2px",
        background: theme.palette.secondary.main,
        width: "50%",
    },
    testimonialCard: {
        borderRadius: "5px",
        width: "100%",
    },
    tour_ul: {
        marginInline: "auto",
        zIndex: 114,
        "@media (max-width: 460px)": {
            marginInline: "unset",
        },
    },
    tour_list: {
        display: "flex",
        gap: "24px",
        alignItems: "flex-start",
    },
    tour_city: {
        width: "130px",
        color: "#FF3131",
        fontWeight: 600,
        textShadow: "1px 1px 1px #000",
        "@media (max-width: 576px)": {
            width: "102px",
        },
    },
    tour_venue: {
        width: "108px",
        textShadow: "1px 1px 2px #000",
        "@media (max-width: 576px)": {
            width: "84px",
        },
    },
    tour_date: {
        width: "92px",
        color: "#FF3131",
        fontWeight: 600,
        textShadow: "1px 1px 1px #000",
        "@media (max-width: 576px)": {
            width: "72px",
        },
    },
    tour_btn: {
        width: "108px",
        cursor: "pointer",
        background: "black",
        border: "1px solid #FFF8F8",
        boxShadow: "0px 0px 5px 1px #ff3131,inset 0px 0px 5px 1px #ff3131",
        color: "#FFFF",
        textShadow: "1px 1px 2px #313131",
        fontWeight: 500,
        borderRadius: "2px",
    },
    workshopBuyBtn: {
        padding: "16px 12px",
        width: "256px",
        cursor: "pointer",
        background: "transparent",
        color: "#fff",
        border: "1px solid #FFF8F8",
        boxShadow: "0px 0px 10px 2px #ff3131,inset 0px 0px 10px 2px #ff3131",
        borderRadius: "4px",
        position: "relative",
        marginBottom: "24px",
    },
    redoutlinedBtn: {
        color: "#fff",
        border: "1px solid #fff",
        "&:hover": {
            border: "1px solid #fff",
        },
    },
}));

export default Edu;
